.position {
    display: flex;
    flex-direction: column;

    .ant-table {
        background: none;
    }

    .ant-table-tbody {
        .ant-table-cell-row-hover {
            background: white !important;
        }

        tr {
            display: block;
            margin-bottom: 30px;

            &:first-child,
            &:last-child {
                margin-bottom: 0px;
            }
        }

        td {
            width: 100%;
            display: block;
            border-radius: 8px;
            background: white;
        }
    }

    .rowItem {
        width: 100%;
        box-sizing: border-box;

        .button,
        .buttonAct {
            background: #FFF3F2;
            color: #E9513E;
            border-color: #E9513E;
            width: 108px;
            height: 32px;
            padding: 0;
            font-size: 12px;
            text-align: center;
            line-height: 32px;
        }

        .buttonAct {
            background: #F2F2F2;
            color: #444444;
            border-color: #B8B8B8;
        }

        .header {
            height: 68px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .companyInfo {
                font-size: 16px;
                font-weight: bold;
                color: #4985FF;
                margin-left: 36px;
                display: flex;
                align-items: center;

                img {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }

            .label {
                font-size: 12px;
                font-weight: 500;
                color: #666666;
                text-align: left;
            }

            .commission {
                display: flex;
                align-items: center;
                justify-content: center;

                .commissionItem {
                    margin-right: 30px;

                    span {
                        color: #FB6362;
                    }
                }
            }

            .userInfo {
                font-size: 14px;
                color: #666666;

                span {
                    margin-left: 10px;
                }
            }

            .timeInfo {
                font-size: 10px;
                color: #707070;
                text-align: right;
            }
        }

        .formHead,
        .formCenter {
            height: 48px;
            display: flex;
            align-items: center;
            background: #F5F9FF;
            padding-left: 40px;
            padding-right: 40px;
            font-size: 16px;
            font-weight: bold;
            color: #444444;

            .positionInfo {
                width: 30%;
            }

            .commission {
                flex: 1;
                text-align: center;

                span {
                    font-size: 16px;
                    color: #E9513E;
                }
            }

            .deadline,
            .creditGold,
            .recruitment,
            .apply,
            .hasHired,
            .state {
                flex: 1;
                text-align: center;

                span {
                    font-size: 16px;
                    color: #707070;
                }
            }

            .operation {
                width: 128px;
                display: flex;
                align-items: center;
                justify-content: center;


                .edit,
                .delete,
                .tuijian {
                    font-size: 14px;
                    width: 30px;
                    text-align: center;
                }

                .edit {
                    color: #4985FF;
                }

                .delete {
                    color: #E9513E;
                }

                .tuijian {
                    color: #444444;
                }

                .vertical {
                    width: 1px;
                    height: 19px;
                    background: #ccc;
                    margin: 0 10px;
                }
            }
        }

        .formCenter {
            margin-top: 5px;
            height: 138px;

            .positionInfo {
                .name {
                    font-size: 18px;
                    font-weight: bold;
                    color: #333333;
                }

                .companyInfo {
                    display: flex;
                    align-items: center;
                    margin: 10px 0;

                    img {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                    }

                    .name {
                        font-size: 14px;
                        color: #4985FF;
                        margin-left: 13px;
                        margin-right: 20px;
                    }
                }

                .label {
                    font-size: 12px;
                    font-weight: 500;
                    color: #666666;
                    text-align: left;
                }

                .timeInfo {
                    font-size: 14px;
                    color: #999999;
                }
            }
        }
    }

    .screening {
        display: block;

        .flexAlignCenter {
            justify-content: space-between;
        }

        .search {
            width: 391px;
            height: 38px;
            background: white;
            border-radius: 0px;
            display: flex;
            align-items: center;
            padding-left: 10px;
            margin-right: 20px;

            .iconfont {
                font-size: 12px;
                margin-right: 0px;
            }

            input {
                flex: 1;
                height: 38px;
                border: none;
                font-size: 12px;
                margin-left: 5px;

                &:focus {
                    outline: none;
                }
            }
        }

        .select {
            margin-right: 6px;
            border-radius: 4px;
        }

        Button {
            background: #4985FF;
        }
    }

    .ant-table-body {
        height: calc(100vh - 286px);
    }

    .Pagination {
        position: fixed;
        bottom: 0px;
        display: flex;
        justify-content: center;
        background: #F3F4F8;
        padding-bottom: 20px;
        padding-top: 10px;
        width: calc(100% - 232px);
    }

    .processSpace {
        .ant-btn-group {
            width: 90px;
            border-radius: 4px;
            overflow: hidden;
        }

        .ant-btn {
            height: 40px;
            background: #4985FF;
            border: none;

            span {
                color: white !important;
            }

        }

        .ant-btn-icon-only::before {
            position: relative;
            width: 1px;
            height: 10px;
            background: #D9E8FF;
            content: '';
        }
    }
}