.financialScreen {
    .ScreenContent {
        display: flex;
        align-items: center;
        margin-top: 12px;

        button {
            margin-left: 20px;
        }

        .ant-input-affix-wrapper {
            margin-right: 20px;
        }

        .iconfont {
            font-size: 14px;
        }
    }

    .labelFlex {
        display: flex;
        flex-wrap: wrap;
    }

    .labelItem {
        padding: 0 12px;
        height: 22px;
        line-height: 22px;
        background: #4985FF;
        border-radius: 2px;
        color: white;
        font-size: 10px;
        margin-right: 10px;
        margin-bottom: 4px;
    }

    .ant-table-body {
        height: calc(100vh - 306px);
    }

    .operation {
        justify-content: center;

        .vertical {
            margin: 0 14px;
            height: 16px;
        }
    }
}

.reserveModal {
    .ant-form-item-label {
        width: 88px;
    }

    .ant-modal-body {
        padding: 36px;
    }

    .flexAlignStart {
        display: flex;
        align-items: flex-start;

        .label {
            flex-shrink: 0;
        }

        .photo {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            margin-left: 14px;
        }
    }

    .uploadButton {
        width: 228px;
        height: 138px !important;

        .iconfont {
            font-family: 'iconfont';
            font-size: 28px;
            color: #1764FE;
            font-weight: bold;
        }

        &:last-child {
            margin-right: 0px;
        }
    }

    .uploadButtonImg {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        img,
        video {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .editor .flexCol .ant-form-item-control-input-content {
        justify-content: start;
        align-items: center;
    }
}