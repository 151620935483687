.ScreenContent {
    display: flex;
    align-items: center;
    margin-top: 12px;

    button {
        margin-left: 20px;
    }

    .ant-input-affix-wrapper {
        margin-right: 20px;
    }

    .iconfont {
        font-size: 14px;
    }

    .basisInfo {
        .ant-select-item-option-content {
            display: flex;
            align-items: center;
        }

        .flexAlignBaseline {
            display: flex;
            align-items: baseline;
        }

        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 13px;
        }

        .nickname {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            margin-right: 10px;
        }

        .gender {
            margin-right: 15px;
            width: 13px;
            height: 13px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .the {
            font-size: 10px;
            color: #333;
        }

        .schoolInfo {
            font-size: 10px;
            color: #333;
            margin-top: 4px;
        }
    }
}

.financialPlatform {
    display: flex;
    margin-top: 20px;

    .title {
        font-size: 20px;
        color: #444444;
        margin-bottom: 20px;
    }

    .student,
    .hunter {
        padding: 20px;
        background: white;
        border-radius: 8px;
    }

    .student {
        width: 100%;
        min-width: 1098px;
        margin-right: 20px;

        .credit,
        .redPacket {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-around;

            .creditItem,
            .redPacketItem {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                background-size: 100% 100%;
                height: 182px;
                margin: 0 auto;
                margin-bottom: 30px;
            }
        }

        .credit {
            .creditItem {
                width: 346px;
                background-image: url('../../../public/assets/countBg.png');

                .creditTitle {
                    font-size: 18px;
                    color: white;
                }

                .creditMoney {
                    font-size: 50px;
                    font-weight: bold;
                    color: white;
                }
            }
        }

        .redPacket {
            .redPacketItem {
                width: 252px;

                .redPacketTitle {
                    font-size: 18px;
                    color: white;
                }

                .redPacketMoney {
                    font-size: 50px;
                    font-weight: bold;
                    color: white;
                }
            }

            .todayCountBg {
                background-image: url('../../../public/assets/todayCountBg.png');
            }

            .monthCountBg {
                background-image: url('../../../public/assets/monthCountBg.png');
            }

        }
    }

    .hunter {
        min-width: 503px;

        .hunterItem {
            width: 463px;
            height: 182px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background-image: url('../../../public/assets/hunterCountBg.png');
            background-size: 100% 100%;
            margin: 0 auto;
            margin-bottom: 30px;

            .hunterTitle {
                font-size: 18px;
                color: white;
            }

            .hunterMoney {
                font-size: 50px;
                font-weight: bold;
                color: white;
            }
        }
    }
}

.FinancialDetails {
    .screen {
        margin: 20px 0;
        background: white;

        .row {
            height: 54px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            .rowWrap {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .rowItem {
                    font-size: 14px;
                    font-weight: bold;
                    color: #444444;
                    margin-right: 30px;
                    cursor: pointer;
                }

                .rowItemAct {
                    color: #4985FF;
                }
            }
        }
    }

    .ant-table-body {
        height: calc(100vh - 422px);

        .operation {
            justify-content: center;
        }
    }
}

.FinancialAudit {
    .ant-table-body {
        height: calc(100vh - 242px);

        .operation {
            justify-content: center;
        }
    }
}