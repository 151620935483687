.pushList {
    .ant-table-body {
        height: calc(100vh - 242px);

        .flexAlignCenter {
            justify-content: center;
        }
    }

    .del {
        cursor: pointer;
        font-size: 22px;
        color: #E9513E;
        font-family: 'iconfont';
    }

    .label {
        display: flex;
        flex-wrap: wrap;

        .labelItem {
            padding: 0 15px;
            height: 24px;
            line-height: 24px;
            border-radius: 14px;
            font-size: 12px;
            margin-right: 14px;
            margin-bottom: 4px;

            &:nth-child(n+3) {
                margin-bottom: 0px;
            }
        }

        .item1 {
            background: #FFE7DF;
            color: #F36B3A;
        }

        .item2 {
            background: #EBF2FF;
            color: #1764FE;
        }

        .item3 {
            background: #FFE7E7;
            color: #E52323;
        }
    }
}

.pushDetails {
    .basisInfo {
        display: flex;
        align-items: center;

        .flexAlignBaseline {
            display: flex;
            align-items: baseline;
        }

        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 13px;
        }

        .nickname {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            margin-right: 10px;
        }

        .gender {
            margin-right: 15px;
            width: 13px;
            height: 13px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .the {
            font-size: 10px;
            color: #333;
        }

        .schoolInfo {
            font-size: 10px;
            color: #333;
            margin-top: 4px;
        }
    }
}