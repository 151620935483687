.resume {
    .screening {
        display: block;

        .flexAlignCenter {
            justify-content: space-between;
        }

        .typeWrap {
            display: flex;
            flex-wrap: wrap;
        }

        .typeItem {
            width: 68px;
            height: 36px;
            border-radius: 4px;
            font-size: 16px;
            color: #707070;
            text-align: center;
            line-height: 36px;
            margin-right: 6px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .typeItemAct {
            background: #4985FF;
            color: white;
        }
    }

    .info {
        .name {
            font-size: 15px;
            font-weight: bold;
        }

        .details {
            font-size: 13px;
            color: #666;
        }
    }

    .ant-table-thead {
        .ant-table-cell {
            font-weight: bold;
        }

    }

    .positionType {
        color: #000000;
        font-weight: bold;
    }

    .operation {
        display: flex;
        align-items: center;
        justify-content: center;
        .flexs{
            color: #1764FE;
            cursor: pointer;
        }
    }

    .vertical {
        margin: 0 32px;
    }

    .ant-table-body {
        height: calc(100vh - 354px);
    }

    .basisInfo {
        display: flex;
        align-items: center;

        .flexAlignBaseline {
            display: flex;
            align-items: baseline;
        }

        .info {
            flex: 1;
        }

        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 13px;
        }

        .nickname {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            margin-right: 10px;
        }

        .gender {
            margin-right: 15px;
            width: 13px;
            height: 13px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .the {
            font-size: 10px;
            color: #333;
        }

        .schoolInfo {
            font-size: 10px;
            color: #333;
            margin-top: 4px;
        }


    }

    .type {
        color: #555555;
    }

    .join {
        font-weight: bold;
    }

    .label {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .labelItem {
            padding: 0 15px;
            height: 24px;
            line-height: 24px;
            border-radius: 14px;
            font-size: 12px;
            margin-right: 14px;
            margin-bottom: 4px;

            &:nth-child(n+3) {
                margin-bottom: 0px;
            }
        }

        .item1 {
            background: #FFE7DF;
            color: #F36B3A;
        }

        .item2 {
            background: #EBF2FF;
            color: #1764FE;
        }

        .item3 {
            background: #FFE7E7;
            color: #E52323;
        }
    }

    .operationItem {
        width: 86px;
        height: 38px;
        border-radius: 4px;
        border: 1px solid #e6e6e6;
        background: white;
        font-size: 12px;
        color: #000000;
        text-align: center;
        line-height: 38px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin-right: 22px;

        &:hover {
            color: white;
            background: #1764FE;
            border-color: #1764FE;
        }
    }
}