.login {
    width: 1920px;
    height: 100vh;
    background-size: 100vw 100%;
    background-image: url('../../../public/assets/background.png');

    header {
        display: flex;
        align-items: center;
        padding-top: 76px;
        padding-left: 150px;

        img {
            width: auto;
            height: auto;
            max-width: 248px;
            max-height: 91px;
        }

        .prompt {
            font-size: 26px;
            font-weight: 500;
            color: white;
            margin-top: -10px;
            margin-left: 15px;
        }
    }
    main{
        margin-top: 103px;
        display: flex;
        align-items: center;
        justify-content: center;
        .invitation{
            width: 800px;
            height: 540px;
        }
    }
}