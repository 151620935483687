.HrDetails {
    display: flex;
    align-items: flex-start;

    .userCard {
        display: flex;
        justify-content: start;
        align-items: start;
    }

    .userInfo {
        width: 458px;
        height: 600px;
        margin-right: 30px;
        background: #FFFFFF;
        padding: 30px;
        opacity: 1;
        border-radius: 12px;
        flex-shrink: 0;
        width: 458px !important;
    }

    .userAvatar {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
    }

    .userName {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin-top: 10px;
        font-size: 16px;

        .gender {
            width: 13px;
            height: 13px;
            margin-left: 10px;
            margin-top: 3px;
        }
    }

    .detailsCard {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-top: 14px;
    }


    .hrlist {
        flex: 1;
        background: white;
        border-radius: 12px;
    }

    .detailsItem {
        flex: 1;
        display: flex;
        justify-content: start;
        align-items: center;
        font-weight: 500;

        .ol {
            width: 4px;
            height: 4px;
            background: #4985FF;
            border-radius: 50%;
            opacity: 1;
            margin-right: 8px;
        }
    }

    .achievement {
        margin-top: 40px;
        font-weight: 500;

        .myAchievement {
            margin-bottom: 14px;
        }

        .achievementItem {
            padding: 4px 16px;
            margin-right: 30px;
            background: #FFE7DF;
            border-radius: 24px;
            color: #F36B3A;
        }

        .achievementItem2 {
            padding: 4px 16px;
            margin-right: 30px;
            background: #EBF2FF;
            border-radius: 24px;
            color: #1764FE;
        }

        .call {
            flex: 1;

            span {
                color: #1764FE;
                font-family: 'iconfont';
            }
        }
    }


    .basisInfo {
        display: flex;
        align-items: center;

        .flexAlignBaseline {
            display: flex;
            align-items: baseline;
        }

        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 13px;
        }

        .nickname {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            margin-right: 10px;
        }

        .gender {
            margin-right: 15px;
            width: 13px;
            height: 13px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .the {
            font-size: 10px;
            color: #333;
        }

        .schoolInfo {
            font-size: 10px;
            color: #333;
            margin-top: 4px;
        }
    }

    .operation {
        justify-content: space-between;
    }

    .vertical {
        margin: 0px;
    }

    .shopping {
        color: #F47E53;
    }

    .agree {
        font-size: 16px;
        color: #4985FF;
        cursor: pointer;
        user-select: none;
    }
}