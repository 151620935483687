.students {
    .screening {
        position: sticky;
        top: 0;
        background: #F3F4F8;
        justify-content: flex-start;
        z-index: 99;

        Button {
            background: #4985FF;
            font-family: 'iconfont';
        }

        .iconfont {
            margin-right: 6px;
        }
    }

    .ant-table-body {
        height: calc(100vh - 294px);
    }

    .basisInfo {
        display: flex;
        align-items: center;

        .flexAlignBaseline {
            display: flex;
            align-items: baseline;
        }

        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 13px;
        }

        .nickname {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            margin-right: 10px;
        }

        .gender {
            margin-right: 15px;
            width: 13px;
            height: 13px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .the {
            font-size: 10px;
            color: #333;
        }

        .schoolInfo {
            font-size: 10px;
            color: #333;
            margin-top: 4px;
        }
    }

    .operation {
        justify-content: space-between;
    }

    .vertical {
        margin: 0px;
    }

    .shopping {
        color: #F47E53;
    }



    .label {
        display: flex;
        flex-wrap: wrap;

        .labelItem {
            padding: 0 15px;
            height: 24px;
            line-height: 24px;
            border-radius: 14px;
            font-size: 12px;
            margin-right: 14px;
            margin-bottom: 4px;

            &:nth-child(n+3) {
                margin-bottom: 0px;
            }
        }

        .item1 {
            background: #FFE7DF;
            color: #F36B3A;
        }

        .item2 {
            background: #EBF2FF;
            color: #1764FE;
        }

        .item3 {
            background: #FFE7E7;
            color: #E52323;
        }
    }

    .agree {
        font-size: 16px;
        color: #4985FF;
        cursor: pointer;
        user-select: none;
    }
}

.operationBox {
    width: 118px;
    height: 40px;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
}

.studentsDetails {
    padding-bottom: 20px;

    .userCard {
        display: flex;
        justify-content: start;
        align-items: start;      
    }

    .userInfo {
        width: 600px;
        height: 600px;
        margin-right: 30px;
        background: #FFFFFF;
        padding: 30px;
        opacity: 1;
        border-radius: 12px;
        flex-shrink: 0;
    }

    .userAvatar {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
    }

    .userName {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin-top: 10px;
        font-size: 16px;

        .gender {
            width: 13px;
            height: 13px;
            margin-left: 10px;
            margin-top: 3px;
        }
    }

    .detailsCard {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-top: 14px;
    }

    .detailsItem {
        flex: 1;
        display: flex;
        justify-content: start;
        align-items: center;
        font-weight: 500;

        .ol {
            width: 4px;
            height: 4px;
            background: #4985FF;
            border-radius: 50%;
            opacity: 1;
            margin-right: 8px;
        }
    }

    .achievement {
        margin-top: 40px;
        font-weight: 500;

        .myAchievement {
            margin-bottom: 14px;
        }

        .achievementItem {
            padding: 4px 16px;
            margin-right: 30px;
            background: #FFE7DF;
            border-radius: 24px;
            color: #F36B3A;
        }

        .achievementItem2 {
            padding: 4px 16px;
            margin-right: 30px;
            background: #EBF2FF;
            border-radius: 24px;
            color: #1764FE;
        }

        .call {
            flex: 1;

            span {
                color: #1764FE;
                font-family: 'iconfont';
            }
        }
    }

    .info {
        display: flex;
        justify-content: start;
        // align-items: flex-end;
        background-color: #FFF;
        padding: 50px;
        border-radius: 12px;

        .infoCard {
            text-align: center;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            font-size: 16px;
            color: #333333;
        }

        .divCard {
            width: 8px;
            opacity: 1;
            border-radius: 8px;
            margin: 0 40px;
            align-self: flex-end;
        }

        .div1 {
            background: #00C286;
            height: 100px;
        }

        .div2 {
            height: 85px;
            background: #F47E53;
        }

        .div3 {
            height: 70px;
            background: #1764FE;
            opacity: 0.6;
        }

        .div4 {
            height: 65px;
            background: #1764FE;
            opacity: 1;
        }

        .div5 {
            height: 50px;
            background: #F47E53;
            opacity: 0.53;
        }

        .div6 {
            height: 35px;
            background: #FFECE3;
        }
    }

    .transactionInfo {
        margin-top: 30px;
        background-color: #FFF;
        border-radius: 12px;

        .tabs {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 30px;

            .tabsCard {
                flex: 1;
                display: flex;
                justify-content: start;
                align-items: center;
            }

            .tabsCardR {
                flex: 1;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            .tabItem {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                padding: 6px 26px;
                cursor: pointer;
            }

            .tabActive {
                font-size: 14px;
                font-weight: 400;
                color: #FFFFFF;
                opacity: 1;
                border-radius: 4px;
                background: #1764FE;
                padding: 6px 26px;
                cursor: pointer;
            }
        }

        .divd {
            width: 100%;
            border: 1px solid #F3F4F8;
        }

        .resumeList {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 40px;
            padding-top: 0px;

            .resumeItem {
                width: 180px;
                margin-top: 20px;

                .resumeTitle {
                    font-size: 18px;
                    color: #333333;
                    margin-bottom: 10px;
                }

                .resumeUrl {
                    width: 180px;
                    height: 254px;
                    background: #FF9797;
                }
            }
        }

        .transactionInfoList {
            &::-webkit-scrollbar {
                width: 0;
            }

            .transactionInfoListItem {
                display: flex;
                justify-content: start;
                align-items: center;
                padding: 12px 30px;

                & div {
                    flex: 1;
                }
            }
        }
    }

    .cardItem {
        display: flex;
        justify-content: start;
        align-items: flex-start;

        &>div {
            flex: 1;
        }

        &>div:nth-child(1) {
            margin-right: 30px;
        }
    }

    .tabsL {
        color: #333333;
        font-size: 20px;
        font-weight: 500;
    }

    .tabsR {
        color: #1764FE;
        font-size: 14px;
        font-weight: 500;

        & span {
            color: #FE1F17;
        }
    }

    .resumeJob {
        width: 580px;
        background: #FFFFFF;
        border-radius: 12px;
        margin-top: 30px;
    }

    .transactionInfoCard {
        flex: 1;
        margin-right: 30px;
    }

    .detailsFooter {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .complete {
        color: #4985FF;
    }

    .notComplete {
        color: #FB6362;
    }

    .tribeBtn {
        text-align: center;
        color: #1764FE;
        font-size: 12px;
        font-weight: 400;
        background: #EBF2FF;
        border-radius: 23px;
        max-width: 100px;
    }
}