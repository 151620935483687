.enterprise {
    .screening {
        background: #F3F4F8;
        justify-content: space-between;
        text-align: center;

        .iconfont {
            font-size: 14px;
            margin-right: 0px;
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            padding: 0;
        }
    }

    .ant-table-body {
        height: calc(100vh - 296px);
    }

    .ant-pagination {
        margin: 0 !important;
        margin-top: 14px !important;
    }

    .type {
        justify-content: flex-start;
    }

    .options {
        display: flex;
        align-items: center;
        justify-content: center;
        .vertical{
            margin: 0 10px;
        }
        .pass,
        .reject {
            width: 86px;
            height: 32px;
            border-radius: 4px;
            text-align: center;
            line-height: 32px;
            color: white;
        }

        .pass {
            background: #1764FE;
        }

        .reject {
            background: #FB6362;
            margin-left: 22px;
        }

        .passText {
            color: #1764FE;
            cursor: pointer;
        }

        .rejectText {
            color: #FB6362;
            cursor: pointer;
        }
        .flexs{
            flex-shrink: 0;
        }
    }

}

.enterpriseModal {
    .ant-modal-content {
        background: none;

        .ant-modal-body {
            background: linear-gradient(180deg, #A3BBF9 0%, #E1E9FF 20%, #FFFFFF 100%);
            border-radius: 9px;
            padding: 60px 38px;
        }
    }

    .name {
        font-size: 26px;
        font-weight: bold;
        color: #333;
        text-align: center;
    }

    .cardDetails {
        width: 530px;
        min-height: 246px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, #D9E8FF 100%);
        border-radius: 4px;
        margin-top: 20px;
        padding: 20px;

        .top {
            display: flex;

            .companyInfo {
                padding-left: 20px;
                flex: 1;

                .companyName {
                    font-size: 26px;
                    font-weight: bold;
                    color: #000000;

                    span {
                        font-weight: 500;
                        margin-left: 10px;
                        font-size: 14px;
                        color: #666666;
                    }
                }

                .label {
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 14px;
                    color: #666;
                    margin-top: 22px;

                    .labelItem {
                        margin-right: 20px;
                    }
                }
            }

            .companyImg {
                width: 175px;
                height: 124px;
                background: #FFACAC;
                flex-shrink: 0;
            }
        }

        .linkman {
            display: flex;
            align-items: center;
            padding-left: 20px;
            margin-top: 30px;

            .name {
                font-size: 24px;
                color: #333;
                font-size: 500;
            }

            .info {
                margin-left: 20px;
                font-size: 14px;
                color: #333;

                .post {
                    span {
                        font-size: 12px;
                        color: #666;
                    }
                }

                .coding {
                    margin-top: 9px;
                    color: #666;
                    letter-spacing: 4px;
                }
            }
        }
    }
}

.enterpriseEditor {
    padding-bottom: 40px !important;

    .title {
        font-size: 18px;
        font-weight: bold;
        color: #1764FE;
        margin-bottom: 12px;
    }

    .titleLabel {
        font-size: 16px;
        font-weight: bold;
        color: #444;
        width: 128px;
        text-align: right;
        margin-bottom: 30px;
    }

    .flexAlignCenter {
        display: flex;
        align-items: flex-start;

        .ant-select.ant-select-in-form-item {
            width: 198px;
        }

        .hangye {
            width: 368px !important;
        }
    }

    .ant-form-item-control-input-content {
        justify-content: flex-start;
    }

    .ant-form-item-label>label {
        font-size: 16px;
        font-weight: bold;
        color: #444;
        justify-content: end;
        width: 128px;
    }

    .required {
        .ant-form-item-label>label::before {
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: '*';
        }
    }

    .uploadButton {
        width: 209px;
        height: 156px;
        border: 2px dashed #E6E6E6;
        border-radius: 2px;
        background: none;

        .iconfont {
            font-family: 'iconfont';
            font-size: 48px;
            color: #1764FE;
        }
    }

    .ant-upload-list {
        height: 0px;
        overflow: hidden;
    }

    video {
        width: 209px;
        height: 156px;
    }

    .ant-image {
        img {
            width: 209px;
            height: 156px;
        }
    }

    .pictureActivity .ant-form-item-control-input-content {
        display: flex;
        flex-wrap: wrap;
    }

    .deleteImg {
        position: relative;
        margin-right: 14px;
        margin-bottom: 10px;
        width: 209px;
        height: 156px;

        &:nth-child(n+2) {
            margin-right: 0px;
        }

        .iconfont {
            position: absolute;
            right: 0;
            z-index: 99;
            width: 30px;
            height: 30px;
            color: white;
            border-radius: 50%;
            text-align: center;
            align-items: center;
            cursor: pointer;
            background: rgba($color: #000000, $alpha: 0.5);
        }
    }

    .submit {
        width: 263px;
        height: 50px;
        background: #1764FE;
        border-radius: 8px;
        margin-left: 128px;
        font-size: 18px;
        font-weight: bold;
        color: white;
        margin-top: 40px;
    }
}

.enterpriseDetails {
    .basicsInfo {
        display: flex;
        justify-content: space-between;

        .info {
            display: flex;
            height: 136px;
            display: flex;

            .enterpriseLogo {
                width: 136px;
                height: 136px;

            }

            .details {
                margin-left: 20px;

                .name {
                    font-size: 20px;
                    font-weight: bold;
                    color: #1764FE;

                    span {
                        color: #F36B3A;
                    }
                }

                .tag {
                    margin-top: 60px;
                    font-size: 16px;
                    font-weight: bold;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }

        .sitting {
            width: 139px;
            height: 80px;
            display: flex;
            color: white;
            font-size: 20px;
            border-radius: 4px;
            align-items: center;
            background: #4985FF;
            flex-direction: column;
            justify-content: center;
        }
    }

    .table {
        display: flex;
        margin-top: 30px;
        align-items: center;

        .tableItem {
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            margin-right: 20px;
            cursor: pointer;
        }

        .tableItemAct {
            color: #1764FE;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                bottom: 0px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: #1764FE;
            }
        }
    }

    .enterpriseContent {

        .row {
            margin-top: 30px;
        }

        .title {
            font-size: 18px;
            font-weight: bold;
            color: black;
        }

        .introduce {
            font-size: 16px;
            color: #555555;
        }

        .photo {
            display: flex;
            flex-wrap: wrap;

            .photoItem {
                width: 266px;
                height: 188px;
                margin-right: 40px;
                margin-bottom: 40px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                border: 1px solid #ccc;
                overflow: hidden;

                .ant-image {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                }

                img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        .principal {
            font-size: 16px;
            color: #555555;
        }
    }

    .tableColumns {
        margin-top: 35px;

        .ant-table-body {
            height: calc(100vh - 471px) !important;
        }

        .info {
            .name {
                font-size: 15px;
                font-weight: bold;
            }

            .details {
                font-size: 13px;
                color: #666;
            }
        }

        .ant-table-thead {
            .ant-table-cell {
                font-weight: bold;
            }

        }

        .positionType {
            color: #000000;
            font-weight: bold;
        }

        .operation {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .vertical {
            margin: 0 32px;
        }

        .ant-table-body {
            height: calc(100vh - 354px);
        }
    }
}