.guideDetails {

    .skillFlex {
        display: flex;
        flex-wrap: wrap;
    }

    .skillItem {
        padding: 0 12px;
        background: #4985FF;
        color: white;
        margin-right: 4px;
        margin-bottom: 4px;
        border-radius: 2px;
        font-size: 12px;
        height: 20px;
        line-height: 20px;
    }
}

.GuideModal {
    .editor {
        padding-top: 30px;
    }

    .flexSbCenter {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .uploadDel {
            cursor: pointer;
            font-size: 20px;
            right: 4px;
            top: 0;
        }
    }
}

.GuideDetailsModal {
    .editor {
        padding-top: 30px;
    }

    .flexSpaceBetween {
        margin-bottom: 0px;

        .ant-form-item-control-input-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .ant-picker {
            width: 188px;
        }

        .ant-form-item-label>label::before {
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: '*';
        }
    }
}