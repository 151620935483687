.tribeDetails {
    .ant-table-body {
        height: calc(100vh - 338px);
    }

    .typeFlex {
        display: flex;
        align-items: center;
    }

    .typeItem {
        padding: 0 12px;
        width: auto;
    }

    .basisInfo {
        display: flex;
        align-items: center;

        .flexAlignBaseline {
            display: flex;
            align-items: baseline;
        }

        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 13px;
        }

        .nickname {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            margin-right: 10px;
        }

        .gender {
            margin-right: 15px;
            width: 13px;
            height: 13px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .the {
            font-size: 10px;
            color: #333;
        }

        .schoolInfo {
            font-size: 10px;
            color: #333;
            margin-top: 4px;
        }
    }

    .shopping {
        color: #F47E53;
    }

    .vertical {
        margin: 0 16px;
    }

    .operation {
        justify-content: center;
    }

    .uploadButtonImg {
        width: 228px;
        height: 98px !important;

        img,
        video {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.financialScreen {
    .uploadButtonImg {
        width: 228px;
        height: 98px !important;

        img,
        video {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.promoteModal {
    .editor {
        padding-top: 30px;
    }

    .flexSbCenter {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .requireds {
        .ant-form-item-control-input-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }

    .uploadButton {
        width: 228px;
        height: 138px !important;
        position: relative;

        .uploadDel {
            position: absolute;
            cursor: pointer;
            font-size: 20px;
            right: 4px;
            top: 0;
        }
    }

    .uploadButtonImg {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-right: 10px;
        margin-bottom: 10px;

        img,
        video {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }

    textarea {
        min-height: 200px !important;
    }

    .ant-input-number {
        width: 100%;
    }
}

.PromoteDetails {
    .content {
        margin-top: 20px !important;
    }

    .album {
        .video {
            margin-right: 10px;
            margin-bottom: 10px;
            position: relative;

            .delete {
                position: absolute;
                top: 4px;
                right: 4px;
                width: 36px;
                height: 36px;
                font-size: 18px;
                text-align: center;
                color: white;
                line-height: 36px;
                border-radius: 50%;
                font-family: 'iconfont';
                cursor: pointer;

                &:hover {
                    background: rgba($color: #000000, $alpha: 0.5);
                    transition: all 0.3s;
                }
            }

        }

        video {
            width: 294px !important;
            height: 165px !important;
        }
    }

    .flexAlignCenter {
        width: 100%;
    }

    .flex1 {
        width: 1px;
        margin-right: 10px;
    }

    .edit {
        font-size: 18px;
        margin: 0;
    }

    .uploadButton {
        width: 294px !important;
        height: 165px !important;
    }
}