// 公共样式type值切换样式
.typeWrap {
    display: flex;
    flex-wrap: wrap;
}

.typeItem {
    width: 68px;
    height: 32px;
    border-radius: 4px;
    font-size: 16px;
    color: #707070;
    text-align: center;
    line-height: 32px;
    margin-right: 6px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.typeItemAct {
    background: #4985FF;
    color: white;
}

// 公共Table样式
.ant-table {
    border-radius: 12px !important;
    overflow: hidden;
}

.ant-table-thead {
    tr {
        height: 60px;

        th {
            font-size: 16px;
            font-weight: bold !important;
            color: #000 !important;
            border-bottom: 1px solid #ccc !important;
        }

    }

    .ant-table-cell {
        background: none;

        &::before {
            width: 0 !important;
        }
    }


}

.ant-table-tbody {

    .ant-table-cell-row-hover {
        background: #D9E8FF !important;
    }
}

.ant-table-body {
    &::-webkit-scrollbar {
        width: 0;
    }
}

// 自定义Modal
.customModal {
    .ant-modal-body {
        padding: 0;
    }
}

// 公共筛选区域
.screening {
    background: #F3F4F8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .iconfont {
        font-size: 14px;
    }

    Button {
        background: #4985FF;
        font-family: 'iconfont';
    }
}


// 公共标签样式
.item1 {
    background: #FFE7DF;
    color: #F36B3A;
}

.item2 {
    background: #EBF2FF;
    color: #1764FE;
}

.item3 {
    background: #FFE7E7;
    color: #E52323;
}

// 公共按钮样式
.edit {
    color: #1764FE;
    cursor: pointer;
}

.reject {
    color: #E52323;
    cursor: pointer;
}

.operation {
    display: flex;
    align-items: center;
}

.requireds {
    label::before {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*'
    }

    .ant-form-item-control-input-content {
        flex: inherit;
        max-width: fit-content;
    }
}

.ant-upload-list {
    height: 0px;
    overflow: hidden;
}

.uploadButtonImg {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .ant-image {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
}

.uploadButton {
    width: 209px;
    height: 156px !important;
    border: 2px dashed #E6E6E6;
    border-radius: 2px;
    background: none;
    text-align: center;

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    .iconfont {
        font-family: 'iconfont';
        font-size: 48px;
        color: #1764FE;
    }
}


.ant-picker-input {
    input {
        text-align: center;
    }
}

.oneRows,
.twoRows,
.threeRows {
    overflow: hidden;
    text-overflow: ellipsis;
}

.twoRows,
.threeRows {
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.oneRows {
    white-space: nowrap;
}

.twoRows {
    -webkit-line-clamp: 2;
}

.threeRows {
    -webkit-line-clamp: 3;
}

.flexSbCenter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ant-drawer-body {
    .item {
        height: 32px;
        font-size: 15px;
        color: black;
        font-weight: bold;
    }

    .more {
        width: 100px;
        margin: 0 auto;
        text-align: center;
    }

    .flexSbCenter {
        margin-bottom: 4px;
    }
}