.LoginView {
    width: 412px;
    height: 566px;
    background: white;
    border-radius: 8px;
    margin-left: 295px;
    padding-top: 68px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    .hello {
        height: 51px;
        font-size: 35px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        color: #1682EE;

    }

    .welcome {
        height: 26px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        color: #BBBBBB;
        margin-top: 10px;
        margin-bottom: 59px;
    }

    .submit {
        width: 331px;
        height: 50px;
        background: #1764FE;
        border-radius: 25px;
        text-align: center;
        line-height: 50px;
        margin-top: 63px;
    }

    .ant-col-offset-8 {
        margin: 0;
    }

    .phoen {
        input {
            height: 100%;
            background: none;
            border: none;
            border-radius: 25px;
            width: 332px;
            height: 49px;
            background: #F6F7FB;
            border-radius: 25px;

        }
    }

    .password {
        .ant-input-affix-wrapper {
            border: none;
            width: 332px;
            height: 49px;
            background: #F6F7FB;
            border-radius: 25px;
            box-sizing: border-box;
        }

        input {
            background: none;
            border: none;
            height: 100%;
        }
    }

    .checkBox {
        .ant-checkbox-wrapper {
            color: #1682F1;
        }
    }
}