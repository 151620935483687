.positionDetails {
    font-weight: 600;

    .flexAlignBaseline {
        display: flex;
        align-items: baseline;
    }

    .header {
        width: 100%;
        height: 226px;
        background: #3F4A5D;
        display: flex;
        justify-content: space-between;
        padding-left: 30px;
        padding-right: 40px;

        .top {
            display: flex;
            align-items: baseline;
            padding-top: 15px;

            .name {
                font-size: 28px;
                color: #FFF;
            }

            .price {
                font-size: 22px;
                font-weight: bold;
                color: #FF5F30;
                margin-left: 20px;
            }

            .species {
                height: 15px;
                background: #4985FF;
                border-radius: 8px;
                line-height: 15px;
                padding: 0 10px;
                font-size: 10px;
                font-family: PingFang SC;
                font-weight: 500;
                color: white;
                margin-left: 20px;
            }
        }

        .label {
            font-size: 12px;
            color: white;
            font-weight: 500;
            margin-top: 14px;
            min-height: 26px;
        }

        .labelBox {
            display: flex;
            align-items: center;
            margin-top: 15px;

            .labelItem {
                height: 26px;
                background: #F3F4F8;
                border: 1px solid #4985FF;
                padding: 0 20px;
                margin-right: 20px;
                border-radius: 20px;
                font-size: 12px;
                font-weight: 500;
                color: #3F4A5D;
                text-align: center;
                line-height: 26px;
            }
        }

        .tiem {
            font-size: 14px;
            font-weight: 500;
            color: white;
            margin-top: 16px;
        }

        .site {
            font-size: 14px;
            font-weight: 500;
            color: white;
            margin-top: 12px;
        }

        .status {
            font-size: 32px;
            font-weight: 500;
            color: white;
            padding-top: 20px;
            text-align: right;
            min-height: 75px;
        }

        .dataBox {
            margin-top: 20px;
            display: flex;
            align-items: flex-end;

            .dataItem {
                font-size: 18px;
                font-weight: 500;
                color: white;
                height: 100px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
            }

            .vertical {
                width: 5px;
                height: 100px;
                border-radius: 9px;
                margin: 0 34px;
            }
        }
    }

    .content {
        width: 100%;
        display: flex;
        margin-top: 25px;
        padding-bottom: 10px;

        .info {
            width: 40%;
            margin-right: 2%;

            .top {
                display: flex;
                align-items: center;

                img {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    margin-right: 24px;
                }

                .name {
                    font-size: 18px;
                    font-family: PingFang SC;
                    color: #444;
                }

                .label {
                    font-size: 14px;
                    color: #999;
                    margin-top: 10px;
                }
            }

            .details {
                font-size: 18px;
                font-family: PingFang SC;
                line-height: 38px;
                color: #444;
                margin-top: 33px;

                p {
                    font-size: 14px;
                }
            }


        }

        .position {
            width: 100%;
            background: white;
            box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.16);
            border-radius: 15px;
            position: relative;
            padding-bottom: 50px;

            .provide {
                height: 48px;
                display: flex;
                padding-right: 20px;
                align-items: center;
                justify-content: flex-end;
                button {
                    width: 88px;

                }
            }

            .recordingHead,
            .detailsItem {
                display: flex;
                align-items: center;
                color: #333;
                font-weight: 600;

                .number {
                    width: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    // text-align: center;
                    .ant-checkbox {
                        margin-bottom: 3px;
                    }
                }

                .basisInfo {
                    text-align: left;
                    width: 288px;
                }

                .officer,
                .days,
                .results,
                .state,
                .induction,
                .departure {
                    width: 100px;
                    text-align: center;
                    // 202305202305补
                    flex: 1;
                }
            }

            .recordingHead {
                border-bottom: 4px solid #F3F4F8;
                height: 75px;
                font-size: 16px;
            }

            .detailsItem {
                font-size: 14px;
                height: 88px;

                .basisInfo {
                    display: flex;
                    align-items: center;

                    .avatar {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        margin-right: 13px;
                    }

                    .nickname {
                        font-size: 16px;
                        font-weight: bold;
                        color: #333333;
                        margin-right: 10px;
                    }

                    .gender {
                        margin-right: 15px;
                        width: 13px;
                        height: 13px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .the {
                        font-size: 10px;
                        color: #333;
                    }

                    .schoolInfo {
                        font-size: 10px;
                        color: #333;
                        margin-top: 4px;
                    }
                }

                .officer {
                    color: #4985FF;
                }

                .induction,
                .departure {
                    font-size: 12px;
                }

                .options {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .vertical {
                    width: 1px;
                    height: 13px;
                    background: #CCCCCC;
                    margin: 0 17px;
                    justify-content: center;
                    text-align: center;
                }

                .agree,
                .refused {
                    font-size: 14px;
                    cursor: pointer;
                    user-select: none;
                }

                .agree {
                    color: #4985FF;
                }

                .refused {
                    color: #E9513E;
                }
            }
        }
    }

    .page {
        position: absolute;
        bottom: 0px;
        background: none;
        width: auto;
        left: 50%;
        transform: translateX(-50%);
        padding-bottom: 10px;
    }
}