.schoolPage {
    .screening {
        position: sticky;
        top: 0;
        background: #F3F4F8;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        .iconfont {
            font-size: 14px;
        }

        Button {
            background: #4985FF;
            font-family: 'iconfont';
        }
    }

    .agree {
        font-size: 16px;
        color: #4985FF;
        cursor: pointer;
        user-select: none;
    }

    .ant-table-body {
        height: calc(100vh - 294px);
    }
}