.layout {
    .ant-layout::-webkit-scrollbar {
        width: none;
        height: none;
    }

    .ant-table-cell {
        border-bottom: none;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
        // background-color: #4985FF;

        img {
            width: auto;
            height: auto;
            max-width: 148px;
            max-height: 60px;
        }
    }

    #components-layout-demo-top-side-2 .logo {
        float: left;
        width: 120px;
        height: 31px;
        margin: 16px 24px 16px 0;
        background: rgba(255, 255, 255, 0.3);
    }

    .ant-row-rtl #components-layout-demo-top-side-2 .logo {
        float: right;
        margin: 16px 0 16px 24px;
    }

    .site-layout {
        background: #F3F4F8;
    }

    .site-layout-background {
        background: #FAFAFA;
        box-shadow: 0px 3px 19px rgba(73, 133, 255, 0.16) !important;
    }

    .ant-layout-header {
        height: 70px;
        display: flex;
        align-items: center;
    }

    .layout-center {
        height: calc(100vh - 118px);
        overflow-y: scroll;
        overflow-x: auto;

        &::-webkit-scrollbar {
            width: 0px;
        }
    }

    .iconfont {
        margin-right: 5px;
        font-size: 20px;
    }

    .ant-menu-title-content {
        font-size: 16px;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
        margin: 0 !important;
        height: 62px !important;
        line-height: 62px !important;
    }

    .ant-menu-light {
        height: calc(100vh - 70px);
        background: #FAFAFA;
        overflow-y: scroll;
        box-shadow: 3px 0px 19px rgba(73, 133, 255, 0.16) !important;

        &::-webkit-scrollbar {
            width: 0;
        }
    }

    .slogan {
        flex: 1;
        font-size: 16px;
        font-weight: bold;
        font-family: PingFang SC;
        margin-left: 17px;
    }

    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        // background: #2D69EB;
        color: white;
        line-height: 40px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    .vertical {
        width: 2px;
        height: 24px;
        background: #999999;
        margin-right: 26px;
        margin-left: 30px;
    }

    .site-layout-background {
        .name {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #AEAEAE;
            margin-left: 20px;
        }
    }


    .exit {
        font-size: 24px;
        margin-right: 40px;
        cursor: pointer;
    }

    .Breadcrumb {
        height: 48px;
        line-height: 48px;
        font-size: 10px;
        color: #999999;
    }

    .ant-breadcrumb li:last-child a {
        color: #4985FF;
    }

    .ant-breadcrumb .anticon {
        font-size: 10px;

        &:last-child {
            color: #4985FF;
        }
    }
}