.ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
}

.submit {
    margin-top: 30px;
}

.title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 16px;
}

.ant-input[disabled] {
    background: none !important;
    color: #333 !important;
    border: none;
}

.ces .ant-table-body {
    height: calc(100vh - 458px);
}

.basisInfo {
    display: flex;
    align-items: center;

    .flexAlignBaseline {
        display: flex;
        align-items: baseline;
    }

    .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 13px;
    }

    .nickname {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        margin-right: 10px;
    }

    .gender {
        margin-right: 15px;
        width: 13px;
        height: 13px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .the {
        font-size: 10px;
        color: #333;
    }

    .schoolInfo {
        font-size: 10px;
        color: #333;
        margin-top: 4px;
    }
}

.screening {
    background: none;
}

.ant-modal-body {
    padding-bottom: 0;
}