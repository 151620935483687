.advertising {
    .ant-table-body {
        height: calc(100vh - 294px);
    }

    .operation {
        justify-content: center;
    }

    .edit,
    .rejectText {
        cursor: pointer;
    }

    .rejectText {
        color: #FB6362;
    }
}

.advertisingModal {
    .ant-modal-content {
        max-height: 580px;
        border-radius: 5px;
    }

    .photo {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        margin-left: 14px;
    }

    .ant-form-item-label {
        width: 88px;
    }

    .ant-form-item-control {
        flex-direction: initial;
        display: block;
    }

    .ant-form-item-control-input-content {
        justify-content: flex-start;
    }

    .uploadButtonImg {
        position: relative;

        .close {
            position: absolute;
            top: 0px;
            right: 0px;
            cursor: pointer;
        }
    }



    .uploadButton {
        width: 375px;
        height: 170px !important;
        margin-right: 20px;
        margin-bottom: 20px;

        .iconfont {
            font-family: 'iconfont';
            font-size: 28px;
            color: #1764FE;
            font-weight: bold;
        }

        &:last-child {
            margin-right: 0px;
        }
    }
}