.tribeDetails {
    .ant-table-body {
        height: calc(100vh - 338px);
    }

    .typeFlex {
        display: flex;
        align-items: center;
    }

    .typeItem {
        padding: 0 12px;
        width: auto;
    }

    .basisInfo {
        display: flex;
        align-items: center;

        .flexAlignBaseline {
            display: flex;
            align-items: baseline;
        }

        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 13px;
        }

        .nickname {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            margin-right: 10px;
        }

        .gender {
            margin-right: 15px;
            width: 13px;
            height: 13px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .the {
            font-size: 10px;
            color: #333;
        }

        .schoolInfo {
            font-size: 10px;
            color: #333;
            margin-top: 4px;
        }
    }

    .shopping {
        color: #F47E53;
    }

    .vertical {
        margin: 0 16px;
    }

    .operation {
        justify-content: center;
    }

    .uploadButtonImg {
        width: 228px;
        height: 98px !important;
    }
}

.dynamicEditorModal {
    .uploadButton {
        width: 228px;
        height: 138px !important;
    }

    .uploadButtonImg {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-right: 10px;
        margin-bottom: 10px;

        img,
        video {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }

    textarea {
        min-height: 200px !important;
    }
    .requireds {
        .ant-form-item-control-input-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }
}

.DynamicDetails {
    .album .ant-image {
        width: 198px !important;
    }

    .content {
        margin-top: 20px !important;
    }

    .comment {
        font-size: 12px;
        color: #666;
        margin-top: 4px;
    }
}